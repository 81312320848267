import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

const ImageContainer = styled.div`
  width: 100%;
  height: ${({height}) => height ? height : '250px'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  div{
    width: 100%;
  height: ${({height}) => height ? height : '250px'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
    img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: ${({recipe}) => recipe && '25px'};
    border-radius: 6px 6px 0 0;
  }

  }

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: ${({recipe}) => recipe && '25px'};
    border-radius: 6px 6px 0 0;
  }
`;

const Thumbnail = ({src, alt, onClick, height, recipe, fallback}) => (
  <ImageContainer recipe={recipe} height={height}>
    <LazyImage onClick={onClick} src={src} alt={alt} onError={(e) => {e.target.src = fallback ? fallback : `${ImageUrl}/images/placeholder/video-placeholder.jpg`}}/>
  </ImageContainer>
);

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  recipe: PropTypes.number,
  height: PropTypes.string,
  fallback:PropTypes.string,
};

export default Thumbnail;
